import React, { useRef, useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Tilt from "react-tilt"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import ReactPlayer from "react-player"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

// Mural Cards Images
import skittlesPrideArrrt from "../images/mural-cards/skittles-pride-arrrt.png"
import saraNashvilleDesign from "../images/mural-cards/sara-nashville-design.png"
import marlonNewarkDesign from "../images/mural-cards/marlon-newark-design.png"
import jaeSanAntonioDesign from "../images/mural-cards/jae-san-antonio-design.png"

// Background Element Images
import raysBg from "../images/rays-bg.svg"
import skittlesLogo from "../images/skittles-logo.png"
import queerCodes from "../images/queer-codes.png"
import cloudOne from "../images/cloud1.png"
import cloudTwo from "../images/cloud2.png"
import cloudThree from "../images/cloud3.png"
import cloudFour from "../images/cloud4.png"
import cloudBig from "../images/cloud-big.png"
import skittlesPridePack from "../images/skittles-pride-pack.gif"
import iconZoom from "../images/icon-zoom.svg"
import iconDownArrow from "../images/icon-down-arrow.svg"

// Influencer Card Images
import ayan from "../images/influencer-cards/ayan.png"
import gregor from "../images/influencer-cards/gregor.png"
import jakk from "../images/influencer-cards/jakk.png"
import shyanne from "../images/influencer-cards/shyanne.png"
import tenderoni from "../images/influencer-cards/tenderoni.png"

// Social Media
import iconFacebook from "../images/icon-facebook.svg"
import iconTwitter from "../images/icon-twitter.svg"
import iconInstagram from "../images/icon-instagram.svg"

// Logos
import logoGlaad from "../images/logo-glaad.png"
import logoMarsWrigley from "../images/logo-mars-wrigley.png"

// Components
// import Layout from "./layout"
// import SEO from "./seo"
import MainNav from "./main-nav"
import Footer from "./footer"

// Influencer Modals
import AyanModal from "./influencer-modals/ayan-modal"
import ShyanneLindsayModal from "./influencer-modals/shyanne-lindsay-modal"
import TenderoniModal from "./influencer-modals/tenderoni-modal"
import JakkFynnModal from "./influencer-modals/jakk-fynn-modal"
import GregorLopesModal from "./influencer-modals/gregor-lopes-modal"

// Mural Modals
import ArrrtaddictModal from "./mural-modals/arrrtaddict-modal"
import SaraMoroniModal from "./mural-modals/sara-moroni-modal"
import MarlonDavilaModal from "./mural-modals/marlon-davila-modal"
import JaeLinModal from "./mural-modals/jae-lin-modal"

// import SkittlesVideo from "../video/SkittlesVideo.mp4"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const App = () => {
  const [navShow, setNavShow] = useState(false)
  const [welcomeVideo, setWelcomeVideo] = useState(false)
  const [celebrateVideo, setCelebrateVideo] = useState(false)
  const [commitmentsVideo, setCommitmentsVideo] = useState(false)
  // Mural Modals
  const [arrrtaddictModalShow, setArrrtaddictModalShow] = useState(false)
  const [saraMoroniModalShow, setSaraMoroniModalShow] = useState(false)
  const [marlonDavilaModalShow, setMarlonDavilaModalShow] = useState(false)
  const [jaeLinModalShow, setJaeLinModalShow] = useState(false)
  // Influencer Modals
  const [ayanModalShow, setAyanModalShow] = useState(false)
  const [shyanneLindsayModalShow, setShyanneLindsayModalShow] = useState(false)
  const [tenderoniModalShow, setTenderoniModalShow] = useState(false)
  const [jakkFynnModalShow, setJakkFynnModalShow] = useState(false)
  const [gregorLopesModalShow, setGregorLopesModalShow] = useState(false)

  const welcomeVideoHandler = () => {
    setWelcomeVideo(true)
    // setCelebrateVideo(false)
    // setCommitmentsVideo(false)
  }
  const celebrateVideoHandler = () => {
    setCelebrateVideo(true)
    // setWelcomeVideo(false)
    // setCommitmentsVideo(false)
  }
  const commitmentsVideoHandler = () => {
    setCommitmentsVideo(true)
    // setWelcomeVideo(false)
    // setCelebrateVideo(false)
  }

  let tl = gsap.timeline()
  let tl2 = gsap.timeline()

  let appWrapperRef = useRef(null)
  let skittlesLogoRef = useRef(null)
  let queerCodesRef = useRef(null)
  let bgRaysRef = useRef(null)
  let mainContentRef = useRef(null)
  let welcomeRef = useRef(null)
  let welcomeVideoRef = useRef(null)
  let videoHldrRef = useRef(null)
  let prideRef = useRef(null)
  let hamburgerRef = useRef(null)
  let scrollElementRef = useRef(null)
  //let scrollerTwoRef = useRef(null)
  let scrollWelcomeRef = useRef(null)
  let scrollWelcomeVideoRef = useRef(null)
  let scrollPrideRef = useRef(null)

  useEffect(() => {
    //const bodyEle = document.querySelector("body")
    //console.log(bgRaysRef)
    tl.to(appWrapperRef, {
      duration: 4,
      css: { backgroundColor: "#6fa2d7", filter: "grayscale(0)" },
    })
      .to(skittlesLogoRef, { duration: 1, opacity: 1, y: 0 }, "-=4")

      .to(
        queerCodesRef.firstElementChild,
        { duration: 2, opacity: 1, scale: 1 },
        "-=4"
      )
      .to(
        queerCodesRef.firstElementChild,
        { duration: 2, opacity: 0, scale: 4, ease: "Power4.out" },
        "-=2"
      )
      .to(queerCodesRef, { duration: 0, css: { display: "none" } })
      .to(
        bgRaysRef.children[1],
        {
          duration: 1,
          top: "15px",
          right: "15px",
          bottom: "15px",
          left: "15px",
        },
        "-=1"
      )
      //.to(bgRaysRef.firstElementChild, { duration: 2, rotation: 45 })
      .to(mainContentRef, { duration: 0, css: { visibility: "visible" } })
      .from(welcomeRef, { duration: 2, scale: 0, ease: "power4.in" }, "-=1")
      .fromTo(
        bgRaysRef.firstElementChild,
        { rotation: 0 },
        { duration: 2, rotation: 60, ease: "power4.in" },
        "-=2"
      )
      .fromTo(
        scrollWelcomeRef,
        { css: { bottom: "-50px", opacity: "0" } },
        { duration: 0.5, css: { bottom: "20px", opacity: "1" } }
      )
      .to(hamburgerRef, {
        duration: 2,
        css: { visibility: "visible", opacity: 1 },
      })

    tl2
      .fromTo(
        welcomeRef,
        { scale: 1, opacity: 1 },
        { duration: 200, scale: 8, opacity: 0, ease: "power3.out" },
        "welcome"
      )
      .to(welcomeRef, { duration: 0, css: { display: "none" } })
      .fromTo(
        scrollWelcomeRef,
        { css: { bottom: "20px", opacity: "1" } },
        { duration: 0.5, css: { bottom: "-50px", opacity: "0" } },
        "-=200"
      )

      .to(videoHldrRef, { duration: 0, css: { pointerEvents: "auto" } })
      .from(welcomeVideoRef, { duration: 200, scale: 0, opacity: 1 }, "-=50")

      .to(
        welcomeVideoRef,
        { duration: 100, scale: 1, opacity: 1, ease: "power3.in" },
        "welcome-video"
      )
      .to(
        scrollWelcomeVideoRef,
        { css: { bottom: "20px", opacity: "1" } },
        "-=200"
      )

      .to(videoHldrRef, { duration: 0, css: { pointerEvents: "none" } })
      .to(welcomeVideoRef, {
        duration: 200,
        // scale: 8,
        css: { transform: "scale(8)", opacity: 0 },
        //opacity: 0,
        ease: "power3.out",
      })
      .to(
        scrollWelcomeVideoRef,
        { css: { bottom: "-50px", opacity: "0" } },
        "-=200"
      )
      .to(welcomeVideoRef, { duration: 0, css: { display: "none" } })

      .from(prideRef, { duration: 200, scale: 0, opacity: 1 }, "-=50")
      .to(scrollPrideRef, { css: { bottom: "20px", opacity: "1" } }, "-=200")
      .to(prideRef, { duration: 200, scale: 1, opacity: 1 })
      .to(prideRef, { duration: 0, scale: 1, opacity: 1 }, "pride")

      .to(
        scrollPrideRef,
        { duration: 0, delay: 1, css: { bottom: "20px", opacity: "0" } },
        "scroll-pride"
      )

      .fromTo(
        bgRaysRef.firstElementChild.firstElementChild,
        { rotation: 60 },
        { duration: 1000, rotation: 360, ease: "none" },
        "-=1000"
      )
    //console.dir(queerCodesRef)

    const myST = ScrollTrigger.create({
      animation: tl2,
      id: "st",
      scroller: ".main-content-inner",
      trigger: ".div2",
      endTrigger: ".div2",
      start: "top bottom",
      end: "bottom bottom",
      scrub: 0.5,
      pin: ".div1",
      pinType: "fixed",
      pinSpacing: false,
      snap: "labelsDirectional",
      //anticipatePin: 1,
      //markers: true,
    })

    const scrollElement = document.querySelector(".main-content-inner")

    gsap.utils.toArray("a.scrolltrigger").forEach(function (a) {
      a.addEventListener("click", function (e) {
        const percent =
          tl2.labels[e.target.getAttribute("data-jump")] / tl2.totalDuration()
        const scrollPos = myST.start + (myST.end - myST.start) * percent
        gsap.to(scrollElement, { duration: 1, scrollTo: scrollPos })
        //console.log(percent, scrollPos)

        hamburger.classList.toggle("close")
        mainNav.classList.toggle("in")
      })
    })

    /***** Background Rays Rotation Control *****/
    gsap.to(".img-rays", {
      scrollTrigger: {
        scroller: ".main-content-inner",
        trigger: ".scrollerTwo",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
      rotation: 240,
    })

    /***** Hamburger Toggle *****/
    const hamburger = document.querySelector(".hamburger")
    const mainNav = document.querySelector(".main-nav")
    hamburger.addEventListener("click", hamburgerHandler)
    function hamburgerHandler() {
      hamburger.classList.toggle("close")
      mainNav.classList.toggle("in")
    }

    /***** Parallax *****/
    document.addEventListener("mousemove", parallax)
    function parallax(e) {
      this.querySelectorAll(".cloud img").forEach(layer => {
        const speed = layer.getAttribute("data-speed")

        const x = (window.innerWidth - e.pageX * speed) / 250
        const y = (window.innerHeight - e.pageX * speed) / 250

        layer.style.transform = `translateX(${x}px) translateY(${y}px)`

        //console.log(layer.style.transform)
      })
    }

    /***** Scroll To Element *****/
    let celebrateOffset = document.getElementById("celebrate")
    let resourcesOffset = document.getElementById("resources")
    let pridePacksOffset = document.getElementById("pridePacks")
    let commitmentsOffset = document.getElementById("commitments")

    let celebrateBtn = document.getElementById("celebrateBtn")
    let resourcesBtn = document.getElementById("resourcesBtn")
    let pridePacksBtn = document.getElementById("pridePacksBtn")
    let commitmentsBtn = document.getElementById("commitmentsBtn")

    celebrateBtn.addEventListener("click", function (e) {
      gsap.to(scrollElement, 1, { scrollTo: { y: celebrateOffset } })
      hamburgerHandler()
      e.preventDefault()
    })
    resourcesBtn.addEventListener("click", function (e) {
      gsap.to(scrollElement, 1, { scrollTo: { y: resourcesOffset } })
      hamburgerHandler()
      e.preventDefault()
    })
    pridePacksBtn.addEventListener("click", function (e) {
      gsap.to(scrollElement, 1, { scrollTo: { y: pridePacksOffset } })
      hamburgerHandler()
      e.preventDefault()
    })
    commitmentsBtn.addEventListener("click", function (e) {
      gsap.to(scrollElement, 1, { scrollTo: { y: commitmentsOffset } })
      hamburgerHandler()
      e.preventDefault()
    })
  }, [])

  useEffect(() => {
    /***** Mural Cards Large Image Open *****/

    const muralCardHldr = document.querySelector(".mural-cards-hldr")
    // muralCardHldr.addEventListener("click", muralCardOpenHandler)
    // function muralCardOpenHandler(e) {
    //   let mainHeader = document.querySelector(".main-header")
    //   if (e.target.nodeName === "IMG") {
    //     let muralCard = e.target.parentElement.parentElement.parentElement
    //     muralCard.nextElementSibling.classList.add("open")
    //     //console.dir(muralCard.nextElementSibling)
    //     if (
    //       navigator.userAgent.match(/Android/i) ||
    //       navigator.userAgent.match(/webOS/i) ||
    //       navigator.userAgent.match(/iPhone/i) ||
    //       navigator.userAgent.match(/iPad/i) ||
    //       navigator.userAgent.match(/iPod/i) ||
    //       navigator.userAgent.match(/BlackBerry/i)
    //     ) {
    //       mainHeader.classList.add("d-none")
    //     }

    //     muralCard.nextElementSibling.addEventListener("click", function (e) {
    //       if (e.target.nodeName !== "IMG") {
    //         mainHeader.classList.remove("d-none")
    //         this.classList.remove("open")
    //       }
    //     })
    //   }
    // }
    /***** Mural Cards Large Image Close *****/
    // const btnCloseMuralCard = document.querySelectorAll(".btn-close-mural-card")
    // btnCloseMuralCard.forEach((btnClose) => {
    //   btnClose.addEventListener("click", function (e) {
    //     e.target.parentElement.classList.remove("open")
    //   })
    // })

    const mainHeader = document.querySelector(".main-header")
    document.querySelector(".arrrt").addEventListener("click", arrrtOpen)
    function arrrtOpen() {
      document.querySelector(".arrrt-lg").classList.add("open")
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)
      ) {
        mainHeader.classList.add("d-none")
      }
    }
    document.querySelector(".sara").addEventListener("click", saraOpen)
    function saraOpen() {
      document.querySelector(".sara-lg").classList.add("open")
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)
      ) {
        mainHeader.classList.add("d-none")
      }
    }
    document.querySelector(".marlon").addEventListener("click", marlonOpen)
    function marlonOpen() {
      document.querySelector(".marlon-lg").classList.add("open")
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)
      ) {
        mainHeader.classList.add("d-none")
      }
    }
    document.querySelector(".jae").addEventListener("click", jaeOpen)
    function jaeOpen() {
      document.querySelector(".jae-lg").classList.add("open")
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)
      ) {
        mainHeader.classList.add("d-none")
      }
    }

    const btnCloseMuralCard = document.querySelectorAll(".btn-close-mural-card")
    btnCloseMuralCard.forEach(btnClose => {
      btnClose.addEventListener("click", function (e) {
        e.target.parentElement.classList.remove("open")
        mainHeader.classList.remove("d-none")
      })
    })
    const CloseMuralCard = document.querySelectorAll(".mural-card-large")
    CloseMuralCard.forEach(cardClose => {
      cardClose.addEventListener("click", function (e) {
        if (e.target.nodeName === "FIGURE") {
          e.target.parentElement.classList.remove("open")
        }
        if (e.target.nodeName === "DIV") {
          e.target.classList.remove("open")
        }
        if (e.target.nodeName !== "IMG") {
          mainHeader.classList.remove("d-none")
        }
        if (e.target.classList.contains("react-transform-component")) {
          e.target.parentElement.parentElement.classList.remove("open")
        }
        //console.log(e)
      })
    })
    /***** Mural Cards Opacity Control *****/
    muralCardHldr.addEventListener("mouseover", muralCardHoverHandler)
    function muralCardHoverHandler(e) {
      //console.log(e);
      if (e.target.nodeName === "IMG") {
        //console.dir(e.target.parentElement.parentElement.parentElement.parentElement.parentElement)
        let parentEle =
          e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement
        parentEle.classList.add("hovered")
        //console.log(e.target.parentElement.parentElement)
        e.target.parentElement.style.opacity = "1"
      }
    }

    muralCardHldr.addEventListener("mouseout", muralCardHoverOutHandler)
    function muralCardHoverOutHandler(e) {
      if (e.target.nodeName === "IMG") {
        let parentEle =
          e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement
        parentEle.classList.remove("hovered")
        e.target.parentElement.style.opacity = ""
      }
    }
  }, [])

  useEffect(() => {
    /***** Influencer Cards Opacity Control *****/
    const partnerList = document.querySelector(".partner-list")
    partnerList.addEventListener("mouseover", partnerListHoverHandler)
    function partnerListHoverHandler(e) {
      //console.log(e)
      if (e.target.nodeName === "IMG") {
        let parentEle =
          e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement
        parentEle.classList.add("hovered")
        e.target.parentElement.parentElement.parentElement.parentElement.style.opacity =
          "1"
      }
    }

    partnerList.addEventListener("mouseout", partnerListHoverOutHandler)
    function partnerListHoverOutHandler(e) {
      if (e.target.nodeName === "IMG") {
        let parentEle =
          e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement
        parentEle.classList.remove("hovered")
        e.target.parentElement.parentElement.parentElement.parentElement.style.opacity =
          ""
      }
    }
  }, [])

  useEffect(() => {
    const tiltElement = document.querySelectorAll(".tilt")
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    ) {
      tiltElement.forEach(tilt => {
        tilt.classList.add("stop")
      })
    }
  }, [])

  useEffect(() => {
    gsap.to(".box-1", {
      scrollTrigger: {
        scroller: ".main-content-inner",
        trigger: ".box-1",
        toggleActions: "restart none resume reverse",
        start: "top 70%",
        end: "center 20%",
        //markers: true,
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: "none",
    })

    gsap.to(".box-2", {
      scrollTrigger: {
        scroller: ".main-content-inner",
        trigger: ".box-2",
        toggleActions: "restart none resume reverse",
        start: "top 70%",
        end: "center 20%",
        //markers: true,
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: "none",
    })

    gsap.to(".box-3", {
      scrollTrigger: {
        scroller: ".main-content-inner",
        trigger: ".box-3",
        toggleActions: "restart none resume reverse",
        start: "top 70%",
        end: "center 20%",
        //markers: true,
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: "none",
    })

    gsap.to(".box-4", {
      scrollTrigger: {
        scroller: ".main-content-inner",
        trigger: ".box-4",
        toggleActions: "restart none resume reverse",
        start: "top 70%",
        end: "center 20%",
        //markers: true,
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: "none",
    })

    gsap.to(".box-5", {
      scrollTrigger: {
        scroller: ".main-content-inner",
        trigger: ".box-5",
        toggleActions: "restart none resume reverse",
        start: "top 70%",
        end: "center 20%",
        //markers: true,
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: "none",
    })

    gsap.to(".box-6", {
      scrollTrigger: {
        scroller: ".main-content-inner",
        trigger: ".box-6",
        toggleActions: "restart none resume reverse",
        start: "top 70%",
        end: "center 20%",
        //markers: true,
      },
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: "none",
    })
  }, [])

  const reloadApp = () => {
    //tl.restart()
    //setNavShow(false)
    setWelcomeVideo(false)
    window.location.reload(false)
  }

  return (
    <>
      <div className="app-wrapper" ref={el => (appWrapperRef = el)}>
        {/* Background Rays */}
        <div className="bg-rays" ref={el => (bgRaysRef = el)}>
          <figure className="mb-0 img-rays">
            <img className="img-fluid" src={raysBg} alt="Rays Background" />
          </figure>
          <div className="after"></div>
        </div>

        {/* Main Logo */}
        <header className="main-header">
          <Container className="position-relative h-100">
            <div className="d-flex justify-content-between">
              <figure
                className="mb-0 skittles-logo"
                ref={el => (skittlesLogoRef = el)}
                onClick={reloadApp}
              >
                <img
                  className="img-fluid"
                  src={skittlesLogo}
                  alt="Logo Skittles"
                />
              </figure>
              <div
                className={`hamburger ${navShow ? "close" : ""}`}
                ref={el => (hamburgerRef = el)}
                onClick={() => setNavShow(!navShow)}
              ></div>
            </div>
          </Container>
        </header>

        {/* Main Navigation */}
        <MainNav navShow={navShow} />

        {/* Queer Codes Logo */}
        <figure className="mb-0 queer-codes" ref={el => (queerCodesRef = el)}>
          <img className="img-fluid" src={queerCodes} alt="Queer Codes" />
        </figure>

        {/* Main Content */}
        <main className="main-content" ref={el => (mainContentRef = el)}>
          <div
            className="main-content-inner"
            ref={el => (scrollElementRef = el)}
            onScroll={() => {
              if (welcomeVideo === true) {
                setWelcomeVideo(false)
              } else if (celebrateVideo === true) {
                setCelebrateVideo(false)
              } else if (commitmentsVideo === true) {
                setCommitmentsVideo(false)
              }
            }}
          >
            <div id="one" className="div div1">
              {/* Welcome Section */}
              <section
                className="content welcome"
                ref={el => (welcomeRef = el)}
              >
                <div className="position-relative">
                  <div className="cloud-1 position-absolute">
                    <figure className="cloud cloud-left mb-0">
                      <img
                        className="img-fluid"
                        src={cloudOne}
                        alt="Welcome Section BG Cloud One"
                        data-speed="-5"
                      />
                    </figure>
                  </div>
                  <h1 className="text-white text-center">
                    DURING PRIDE, ONLY ONE RAINBOW MATTERS.
                    <span className="d-block mt-5 mb-4">YOURS.</span>
                  </h1>
                  <p className="text-white text-center">
                    This year, we created Skittles QueeR Codes (just like a QR
                    code, but queer) and we’re partnering with Blair Imani to
                    shine a light on LGBTQ+ artists, influencers and creators
                    during Pride.
                  </p>
                  <div className="cloud-2 position-absolute">
                    <figure className="cloud cloud-right mb-0">
                      <img
                        className="img-fluid"
                        src={cloudOne}
                        alt="Welcome Section BG Cloud Two"
                        data-speed="5"
                      />
                    </figure>
                  </div>
                </div>
              </section>

              <div
                className="scroll scroll-welcome text-white position-absolute d-flex align-items-center"
                ref={el => (scrollWelcomeRef = el)}
              >
                <span>Scroll to</span>{" "}
                <figure className="mb-0 mx-3">
                  <img src={iconDownArrow} />
                </figure>{" "}
                <span>See More</span>
              </div>

              {/* Welcome Video Section */}
              <section
                className="content welcome-video"
                ref={el => (welcomeVideoRef = el)}
              >
                <div className="position-relative">
                  <div className="cloud-1 position-absolute">
                    <figure className="cloud cloud-left mb-0">
                      <img
                        className="img-fluid"
                        src={cloudTwo}
                        alt="Welcome Video Section BG Cloud One"
                        data-speed="-5"
                      />
                    </figure>
                  </div>
                  <div
                    className="video-hldr d-flex flex-column justify-content-center align-items-center"
                    ref={el => (videoHldrRef = el)}
                  >
                    <div className="ratio h-100">
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        url="https://youtu.be/X3ZaOboUchM"
                        //url="https://d14pr3cu5atb0x.cloudfront.net/cms/Case-study-video-created-with-Animoto-video-maker-online-89f2f1d3a2.mp4"
                        //url={SkittlesVideo}
                        playing={welcomeVideo}
                        controls={true}
                        onReady={() => console.log("onReady")}
                        onPlay={welcomeVideoHandler}
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                              disablepictureinpicture: "true",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="cloud-2 position-absolute">
                    <figure className="cloud cloud-right mb-0">
                      <img
                        className="img-fluid"
                        src={cloudOne}
                        alt="Welcome Video Section BG Cloud Two"
                        data-speed="5"
                      />
                    </figure>
                  </div>
                </div>
              </section>

              <div
                className="scroll scroll-welcome-video text-white position-absolute d-flex align-items-center"
                ref={el => (scrollWelcomeVideoRef = el)}
              >
                <span>Scroll to</span>{" "}
                <figure className="mb-0 mx-3">
                  <img src={iconDownArrow} />
                </figure>{" "}
                <span>See More</span>
              </div>

              {/* Pride Section */}
              <section className="content pride" ref={el => (prideRef = el)}>
                <div className="position-relative">
                  <div className="cloud-1 position-absolute">
                    <figure className="cloud cloud-left mb-0">
                      <img
                        className="img-fluid"
                        src={cloudOne}
                        alt="Pride Section BG Cloud One"
                        data-speed="-5"
                      />
                    </figure>
                  </div>
                  <h1 className="text-white text-center mb-4">
                    THERE’S MORE THAN ONE WAY TO SEE A RAINBOW.
                  </h1>
                  <p className="text-white text-center lead">
                    That’s why we’re partnering with local LGBTQ+ artists to
                    create original murals that express how they see the rainbow
                    and celebrate Pride.
                  </p>
                  <div className="cloud-2 position-absolute">
                    <figure className="cloud cloud-right mb-0">
                      <img
                        className="img-fluid"
                        src={cloudThree}
                        alt="Pride Section BG Cloud Two"
                        data-speed="5"
                      />
                    </figure>
                  </div>
                </div>
              </section>
              <div
                className="scroll scroll-pride text-white position-absolute d-flex align-items-center"
                ref={el => (scrollPrideRef = el)}
              >
                <span>Scroll to</span>{" "}
                <figure className="mb-0 mx-3">
                  <img src={iconDownArrow} />
                </figure>{" "}
                <span>See More</span>
              </div>
            </div>

            {/* ScrollTrigger One Helper */}
            <div className="div div2"></div>

            {/* Normal Scroll Helper */}
            <div className="div div3 text-center" id="two">
              <div className="scrollerTwo">
                {/* Mural Cards Section */}
                <div className="mural-card-large arrrt-lg">
                  <p className="text-white text-center position-absolute w-100 mb-0 d-lg-none">
                    Pinch to zoom in on the image
                  </p>

                  <figure className="mb-0 w-100">
                    <TransformWrapper wheel={{ wheelEnabled: false }}>
                      <TransformComponent>
                        <img
                          className="mural-card-img"
                          src={skittlesPrideArrrt}
                          alt="@ARRRTADDICT"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </figure>

                  <div className="btn-close-mural-card"></div>
                </div>
                <div className="mural-card-large sara-lg">
                  <p className="text-white text-center position-absolute w-100 mb-0 d-lg-none">
                    Pinch to zoom in on the image
                  </p>
                  <figure className="mb-0 w-100">
                    <TransformWrapper wheel={{ wheelEnabled: false }}>
                      <TransformComponent>
                        <img
                          className="mural-card-img"
                          src={saraNashvilleDesign}
                          alt="@SARAMORONIPIZZA"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </figure>
                  <div className="btn-close-mural-card"></div>
                </div>
                <div className="mural-card-large jae-lg">
                  <p className="text-white text-center position-absolute w-100 mb-0 d-lg-none">
                    Pinch to zoom in on the image
                  </p>
                  <figure className="mb-0 w-100">
                    <TransformWrapper wheel={{ wheelEnabled: false }}>
                      <TransformComponent>
                        <img
                          className="mural-card-img"
                          src={jaeSanAntonioDesign}
                          alt="@DOODLEMEALIVE"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </figure>
                  <div className="btn-close-mural-card"></div>
                </div>
                <div className="mural-card-large marlon-lg">
                  <p className="text-white text-center position-absolute w-100 mb-0 d-lg-none">
                    Pinch to zoom in on the image
                  </p>

                  <figure className="mb-0 w-100">
                    <TransformWrapper wheel={{ wheelEnabled: false }}>
                      <TransformComponent>
                        <img
                          className="mural-card-img"
                          src={marlonNewarkDesign}
                          alt="@7OVECHILD"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </figure>

                  <div className="btn-close-mural-card"></div>
                </div>
                <section className="py-5 box-1">
                  <div className="container mb-5">
                    <p className="text-white text-center lead mb-5">
                      Click through the images and links below to learn more
                      about our artist partners.
                    </p>
                    <div className="pb-5">
                      <div className="row no-gutters justify-content-center pb-md-5 mural-cards-hldr">
                        <div className="position-relative col-md-3 mb-5 mb-md-0">
                          <div className="mural-card px-2">
                            <Tilt className="tilt">
                              <figure className="mb-0 w-100">
                                <img
                                  className="mural-card-img arrrt"
                                  src={skittlesPrideArrrt}
                                  alt="@ARRRTADDICT"
                                />
                                <img
                                  className="icon-zoom d-lg-none"
                                  src={iconZoom}
                                  alt="Icon Zoom"
                                />
                              </figure>
                            </Tilt>
                            <a
                              href="#"
                              className="btn btn-danger rounded-pill mt-4"
                              onClick={() => setArrrtaddictModalShow(true)}
                            >
                              @ARRRTADDICT
                            </a>
                          </div>
                        </div>
                        <div className="position-relative col-md-3 mb-5 mb-md-0">
                          <div className="mural-card px-2">
                            <Tilt className="tilt">
                              <figure className="mb-0 w-100">
                                <img
                                  className="mural-card-img sara"
                                  src={saraNashvilleDesign}
                                  alt="@SARAMORONIPIZZA"
                                />
                                <img
                                  className="icon-zoom d-lg-none"
                                  src={iconZoom}
                                  alt="Icon Zoom"
                                />
                              </figure>
                            </Tilt>
                            <a
                              href="#"
                              className="btn btn-danger rounded-pill mt-4"
                              onClick={() => setSaraMoroniModalShow(true)}
                            >
                              @SARAMORONIPIZZA
                            </a>
                          </div>
                        </div>
                        <div className="position-relative col-md-3 mb-5 mb-md-0">
                          <div className="mural-card px-2">
                            <Tilt className="tilt">
                              <figure className="mb-0 w-100">
                                <img
                                  className="mural-card-img marlon"
                                  src={marlonNewarkDesign}
                                  alt="@7OVECHILD"
                                />
                                <img
                                  className="icon-zoom d-lg-none"
                                  src={iconZoom}
                                  alt="Icon Zoom"
                                />
                              </figure>
                            </Tilt>
                            <a
                              href="#"
                              className="btn btn-danger rounded-pill mt-4"
                              onClick={() => setMarlonDavilaModalShow(true)}
                            >
                              @7OVECHILD
                            </a>
                          </div>
                        </div>
                        <div className="position-relative col-md-3 mb-5 mb-md-0">
                          <div className="mural-card px-2">
                            <Tilt className="tilt">
                              <figure className="mb-0 w-100">
                                <img
                                  className="mural-card-img jae"
                                  src={jaeSanAntonioDesign}
                                  alt="@DOODLEMEALIVE"
                                />
                                <img
                                  className="icon-zoom d-lg-none"
                                  src={iconZoom}
                                  alt="Icon Zoom"
                                />
                              </figure>
                            </Tilt>
                            <a
                              href="#"
                              className="btn btn-danger rounded-pill mt-4"
                              onClick={() => setJaeLinModalShow(true)}
                            >
                              @DOODLEMEALIVE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* Celebrate Section */}
                <section className="py-5 celebrate box-2" id="celebrate">
                  <div className="container px-4 px-md-3 my-5">
                    <div className="row">
                      <div className="position-relative col-md-6">
                        <h1 className="text-white text-center mb-3 pt-4 pt-md-0 pt-lg-4">
                          SHINING A LIGHT ON LGBTQ+ EXPERIENCES.
                        </h1>
                        <p className="text-white text-center lead">
                          Each and every perspective is worth celebrating, and
                          this year, we want to shine a light on some of the
                          ones that we think should be seen.
                        </p>
                        <div className="cloud-1">
                          <figure className="cloud cloud-left mt-md-4 mt-lg-5 mb-0">
                            <img
                              className="img-fluid"
                              src={cloudOne}
                              alt="Celebrate Section BG Cloud One"
                              data-speed="-5"
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="position-relative col-md-6">
                        <div className="ratio ratio-16x9 mt-5 mt-md-0">
                          {/* <iframe
                            className="embed-responsive-item"
                            src="https://www.youtube.com/watch?v=VOv5AQaq1_I"
                            allowFullScreen
                          ></iframe> */}
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=VOv5AQaq1_I"
                            width="100%"
                            height="100%"
                            controls={true}
                            playing={celebrateVideo}
                            onPlay={celebrateVideoHandler}
                          />
                        </div>
                        <div className="cloud-2 position-absolute d-none d-md-block">
                          <figure className="cloud cloud-right mb-0">
                            <img
                              className="img-fluid"
                              src={cloudFour}
                              alt="Celebrate Section BG Cloud Two"
                            />
                          </figure>
                        </div>
                        <div className="cloud-3 position-absolute">
                          <figure className="cloud cloud-right mb-0">
                            <img
                              className="img-fluid"
                              src={cloudThree}
                              alt="Celebrate Section BG Cloud Three"
                              data-speed="5"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Influencer Cards Section */}
                <section className="pt-5 mt-5 partners box-3">
                  <div className="container px-4 px-md-3">
                    <h1 className="text-white text-center text-uppercase">
                      LEARN MORE ABOUT OUR PARTNERS
                    </h1>
                    <p className="text-white text-center lead">
                      And click through to support them if you like what you
                      see!
                    </p>

                    <div className="d-flex flex-column flex-md-row justify-content-center pt-md-4 mt-5 partner-list">
                      <div className="partner position-relative px-2 mb-5 mb-md-0">
                        <div
                          className=""
                          data-tilt
                          onClick={() => setAyanModalShow(true)}
                        >
                          <Tilt className="tilt">
                            <figure className="mb-0">
                              <img
                                className="img-fluid w-100"
                                src={ayan}
                                alt="@THEMAKEUPMARIACHI"
                              />
                            </figure>
                            <a
                              href="#"
                              className="btn btn-primary rounded-pill position-absolute"
                            >
                              @THEMAKEUPMARIACHI
                            </a>
                          </Tilt>
                        </div>
                      </div>
                      <div className="partner position-relative px-2 mb-5 mb-md-0">
                        <div
                          className=""
                          data-tilt
                          onClick={() => setShyanneLindsayModalShow(true)}
                        >
                          <Tilt className="tilt">
                            <figure className="mb-0">
                              <img
                                className="img-fluid w-100"
                                src={shyanne}
                                alt="@_SHYANNELINDSAY"
                              />
                            </figure>
                            <a
                              href="#"
                              className="btn btn-primary rounded-pill position-absolute"
                            >
                              @_SHYANNELINDSAY
                            </a>
                          </Tilt>
                        </div>
                      </div>
                      <div className="partner position-relative px-2 mb-5 mb-md-0">
                        <div
                          className=""
                          data-tilt
                          onClick={() => setTenderoniModalShow(true)}
                        >
                          <Tilt className="tilt">
                            <figure className="mb-0">
                              <img
                                className="img-fluid w-100"
                                src={tenderoni}
                                alt="@TENDERONI88"
                              />
                            </figure>
                            <a
                              href="#"
                              className="btn btn-primary rounded-pill position-absolute"
                            >
                              @TENDERONI88
                            </a>
                          </Tilt>
                        </div>
                      </div>
                      <div className="partner position-relative px-2 mb-5 mb-md-0">
                        <div
                          className=""
                          data-tilt
                          onClick={() => setJakkFynnModalShow(true)}
                        >
                          <Tilt className="tilt">
                            <figure className="mb-0">
                              <img
                                className="img-fluid w-100"
                                src={jakk}
                                alt="@JAKKFYNN"
                              />
                            </figure>
                            <a
                              href="#"
                              className="btn btn-primary rounded-pill position-absolute"
                            >
                              @JAKKFYNN
                            </a>
                          </Tilt>
                        </div>
                      </div>
                      <div className="partner position-relative px-2 mb-5 mb-md-0">
                        <div
                          className=""
                          data-tilt
                          onClick={() => setGregorLopesModalShow(true)}
                        >
                          <Tilt className="tilt">
                            <figure className="mb-0">
                              <img
                                className="img-fluid w-100"
                                src={gregor}
                                alt="@PLAYWITHME39_"
                              />
                            </figure>
                            <a
                              href="#"
                              className="btn btn-primary rounded-pill position-absolute"
                            >
                              @PLAYWITHME39_
                            </a>
                          </Tilt>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="container-fluid position-relative partners-clouds pb-5 mb-5 d-none d-md-block">
                  <div className="row no-gutters pb-5">
                    <div className="cloud-1 position-absolute">
                      <figure className="cloud cloud-left mb-0">
                        <img
                          className="w-100"
                          src={cloudTwo}
                          alt="Celebrate Section BG Cloud One"
                          data-speed="-5"
                        />
                      </figure>
                    </div>
                    <div className="cloud-2 position-absolute">
                      <figure className="cloud cloud-right mb-0">
                        <img
                          className="w-100"
                          src={cloudOne}
                          alt="Celebrate Section BG Cloud Two"
                          data-speed="5"
                        />
                      </figure>
                    </div>
                  </div>
                </div>

                {/* Resources Section */}
                <section className="pt-5 resources box-4" id="resources">
                  <div className="container px-4 px-md-3 mt-5">
                    <h1 className="text-white text-center text-uppercase mb-3 pt-4">
                      GLAAD RESOURCES
                    </h1>
                    <p className="text-white text-center lead section-para mb-2">
                      Some important information to have from our partners at
                      GLAAD, whether you’re in the community or an ally of it.
                    </p>
                    <div className="row align-items-center">
                      <div className="position-relative col-md-6 col-lg-4 my-4">
                        <a
                          href="https://www.glaad.org/about/history "
                          target="_blank"
                          className="btn btn-block text-white text-uppercase rounded-pill"
                          rel="noopener noreferrer"
                        >
                          GLAAD History and Highlights
                        </a>
                        <p className="text-white text-center mt-3 tile-para">
                          Learn more about the organization that’s pushing for
                          positive change for the LGBTQ community, each and
                          every day.
                        </p>
                      </div>
                      <div className="position-relative col-md-6 col-lg-4 my-4">
                        <a
                          href="https://www.glaad.org/blog/how-support-trans-community-pride"
                          target="_blank"
                          className="btn btn-block text-white text-uppercase rounded-pill"
                          rel="noopener noreferrer"
                        >
                          How To Support The Trans{" "}
                          <span className="d-block">Community This Pride</span>
                        </a>
                        <p className="text-white text-center mt-3 tile-para">
                          As discrimination and violence facing trans people
                          continues, learn how you can support and show
                          solidarity with the trans community this June and
                          beyond.
                        </p>
                      </div>
                      <div className="position-relative col-md-6 col-lg-4 my-4">
                        <a
                          href="https://www.glaad.org/equalityact"
                          target="_blank"
                          className="btn btn-block text-white text-uppercase rounded-pill"
                          rel="noopener noreferrer"
                        >
                          The Equality Act
                        </a>
                        <p className="text-white text-center mt-3 tile-para">
                          Learn more about the landmark legislation that would
                          provide comprehensive protections for LGBTQ people,
                          and how you can help it become law.
                        </p>
                      </div>

                      <div className="position-relative col-md-6 col-lg-4 my-4">
                        <a
                          href="https://www.glaad.org/transgender/allies"
                          target="_blank"
                          className="btn btn-block text-white text-uppercase rounded-pill"
                          rel="noopener noreferrer"
                        >
                          Tips for Allies of the Trans Community
                        </a>
                        <p className="text-white text-center mt-3 tile-para">
                          Information about how you can move toward becoming a
                          better ally to transgender people.
                        </p>
                      </div>
                      <div className="position-relative col-md-6 col-lg-4 my-4">
                        <a
                          href="https://www.glaad.org/resourcelist"
                          target="_blank"
                          className="btn btn-block text-white text-uppercase rounded-pill"
                          rel="noopener noreferrer"
                        >
                          LGBTQ Resources List
                        </a>
                        <p className="text-white text-center mt-3 tile-para">
                          Find out more about other resources supporting the
                          LGBTQ community.
                        </p>
                      </div>
                      <div className="position-relative col-md-6 col-lg-4 my-4">
                        <a
                          href="https://donate.glaad.org/site/Donation2;jsessionid=00000000.app20013b?1400.donation=form1&df_id=1400&mfc_pref=T&NONCE_TOKEN=12E9E62D04BC0336FD3FFEF69AB6BBA4"
                          target="_blank"
                          className="btn btn-block text-white text-uppercase rounded-pill"
                          rel="noopener noreferrer"
                        >
                          Donate to GLAAD
                        </a>
                        <p className="text-white text-center mt-3 tile-para">
                          Your support helps us continue GLAAD’s critical,
                          culture-changing work. Every contribution counts!
                        </p>
                      </div>

                      <div className="position-relative col-md-6 col-lg-4 my-4">
                        <figure className="mb-0 px-2">
                          <img
                            className="img-fluid"
                            src={logoGlaad}
                            alt="Logo Glaad"
                          />
                        </figure>
                      </div>

                      <div className="position-relative col-md-6 col-lg-4 my-4 align-self-start">
                        <p className="text-white text-center lead social-media-title">
                          Follow GLAAD:
                        </p>
                        <div className="d-flex justify-content-center align-items-center">
                          <a
                            href="https://www.facebook.com/GLAAD/"
                            target="_blank"
                            className="mx-3"
                            rel="noopener noreferrer"
                          >
                            <img src={iconFacebook} alt="Icon Facebook" />
                          </a>
                          <a
                            href="https://twitter.com/glaad "
                            target="_blank"
                            className="mx-3"
                            rel="noopener noreferrer"
                          >
                            <img src={iconTwitter} alt="Icon Twitter" />
                          </a>
                          <a
                            href="https://www.instagram.com/glaad/ "
                            target="_blank"
                            className="mx-3"
                            rel="noopener noreferrer"
                          >
                            <img src={iconInstagram} alt="Icon Instagram" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="container-fluid position-relative resources-clouds pb-5 mb-5 d-none d-md-block">
                  <div className="row no-gutters pb-5">
                    <div className="cloud-1 position-absolute">
                      <figure className="cloud cloud-right mb-0">
                        <img
                          className="img-fluid w-100"
                          src={cloudFour}
                          alt="Resources Section BG Cloud One"
                          data-speed="10"
                        />
                      </figure>
                    </div>
                  </div>
                </div>

                {/* Pride Packs Section */}
                <section className="py-5 buy-packs box-5" id="pridePacks">
                  <div className="container px-4 px-md-3 my-5">
                    <div className="row">
                      <div className="position-relative col-md-6 col-lg-5">
                        <figure className="mb-0">
                          <img
                            className="img-fluid"
                            src={skittlesPridePack}
                            alt="Skittles Pride Pack"
                          />
                        </figure>
                      </div>
                      <div className="position-relative col-md-6 col-lg-7">
                        <h1 className="text-white text-center pt-4 mt-md-5 mb-3">
                          SKITTLES PRIDE PACKS
                        </h1>
                        <p className="text-white text-center">
                          During Pride, only one rainbow matters—which is why
                          we’re giving up ours to support and celebrate the
                          LGBTQ+ community.{" "}
                        </p>
                        <p className="text-white text-center">
                          For every Pride Pack sold, we’ll donate $1 to GLAAD
                          (up to $100K).*{" "}
                        </p>

                        <p className="text-white text-center">
                          You can get yours right here, at{" "}
                          <a
                            href="https://www.walmart.com/browse/skittles-pride/0/0/?_refineresult=true&_be_shelf_id=2951211&search_sort=100&facet=shelf_id:2951211"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Walmart.com
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://www.target.com/p/skittles-original-chewy-candy-pride-pack-sharing-size-bag-15-5oz/-/A-82227679#lnk=sametab "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Target.com
                          </a>
                          , or in-store at select Walmart, Kroger, Albertson’s
                          and other select retail locations nationwide.
                        </p>

                        <p className="text-white text-center note">
                          * For every 4 oz or 15.6 oz SKITTLES® packs purchased
                          between May 17, 2021 through July 5, 2021 at a
                          participating retailer, Mars Wrigley Confectionery US,
                          LLC will donate $1 to GLAAD up to a maximum donation
                          of $100,000. Your purchase is not tax deductible. For
                          terms and conditions, visit
                          http://bit.ly/SKITTLESGLAAD. For more information
                          about GLAAD, visit glaad.org.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="container-fluid position-relative buy-packs-clouds pb-5 mb-5 d-none d-md-block">
                  <div className="row no-gutters pb-5">
                    <div className="cloud-1 position-absolute">
                      <figure className="cloud cloud-left mb-0">
                        <img
                          className="w-100"
                          src={cloudFour}
                          alt="Pride Section BG Cloud One"
                          data-speed="-5"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                {/* Commitments Section */}
                <section className="pt-5 commitments box-6" id="commitments">
                  <div className="container px-4 px-md-3 mt-5">
                    <div className="row align-items-center">
                      <div className="position-relative col-md-6 col-lg-7">
                        <h2 className="text-white text-center mb-3">
                          COMMITTED TO INCLUSIVITY, INSIDE AND OUTSIDE OUR
                          COMPANY.
                        </h2>
                        <p className="text-white text-center">
                          At Mars Wrigley, we’re committed to creating a company
                          culture that’s inclusive, diverse, and supportive of
                          our LGBTQ+ associates. But don’t listen to us, listen
                          to our associates.
                        </p>
                        <figure className="mb-0">
                          <img
                            className="img-fluid"
                            src={logoMarsWrigley}
                            alt="Mars Wrigley Logo"
                          />
                        </figure>
                      </div>
                      <div className="position-relative col-md-6 col-lg-5">
                        <div className="ratio ratio-4x3 mt-5 mt-md-0">
                          {/* <iframe
                            className="embed-responsive-item"
                            src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                            allowFullScreen
                          ></iframe> */}
                          <ReactPlayer
                            url="https://youtu.be/R5FDJbIEnFc"
                            width="100%"
                            height="100%"
                            controls={true}
                            playing={commitmentsVideo}
                            onPlay={commitmentsVideoHandler}
                          />
                        </div>
                        <div className="cloud-1 position-absolute d-none d-md-block">
                          <figure className="cloud cloud-right mb-0">
                            <img
                              className="w-100"
                              src={cloudThree}
                              alt="Commitments Section BG Cloud One"
                              data-speed="5"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="container-fluid position-relative commitments-clouds pb-5 mb-5 d-none d-md-block">
                  <div className="row no-gutters pb-5">
                    <div className="cloud-1 position-absolute">
                      <figure className="cloud cloud-left mb-0">
                        <img
                          className="w-100"
                          src={cloudTwo}
                          alt="Commitments Section BG Cloud Two"
                          data-speed="-5"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                {/* Sign Up Section */}
                {/* <section className="pt-5 signup">
                <div className="container mt-5">
                  <p className="text-white text-center lead">
                    If you’d like to receive the latest news about Skittles, as
                    well as info about events and promotions, simply enter your
                    email below!
                  </p>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your eMail Here"
                      aria-label="Your eMail Here"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button className="btn text-uppercase" type="button">
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </section> */}
                <div className="cloud-big mt-5 mt-md-0">
                  <figure className="cloud cloud-center mb-0">
                    <img
                      className="img-fluid w-100"
                      src={cloudBig}
                      alt="Cloud Big"
                    />
                  </figure>
                </div>

                {/* Main Footer */}
                <Footer />
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* Influencer Modals */}
      <AyanModal show={ayanModalShow} onHide={() => setAyanModalShow(false)} />
      <ShyanneLindsayModal
        show={shyanneLindsayModalShow}
        onHide={() => setShyanneLindsayModalShow(false)}
      />
      <TenderoniModal
        show={tenderoniModalShow}
        onHide={() => setTenderoniModalShow(false)}
      />
      <JakkFynnModal
        show={jakkFynnModalShow}
        onHide={() => setJakkFynnModalShow(false)}
      />
      <GregorLopesModal
        show={gregorLopesModalShow}
        onHide={() => setGregorLopesModalShow(false)}
      />
      <ArrrtaddictModal
        show={arrrtaddictModalShow}
        onHide={() => setArrrtaddictModalShow(false)}
      />
      <SaraMoroniModal
        show={saraMoroniModalShow}
        onHide={() => setSaraMoroniModalShow(false)}
      />

      <MarlonDavilaModal
        show={marlonDavilaModalShow}
        onHide={() => setMarlonDavilaModalShow(false)}
      />

      <JaeLinModal
        show={jaeLinModalShow}
        onHide={() => setJaeLinModalShow(false)}
      />
    </>
  )
}

export default App
