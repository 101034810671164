import React, { useState, useEffect } from "react"
import Cookies from "universal-cookie"


import Layout from "../../components/SkittlesPrideComponents/components/layout"
import SEO from "../../components/SkittlesPrideComponents/components/seo"
import Agegate from "../../components/SkittlesPrideComponents/components/agegate"
import App from "../../components/SkittlesPrideComponents/components/app"

const IndexPage = () => {
  // const [oneTrust, setOneTrust] = useState(false)
  // const [ageGateShow, setAgeGateShow] = useState(true)
  const [appShow, setAppShow] = useState(false)

  let ageCookie = new Cookies()
  // useEffect(() => {
  //   if (ageCookie.get("isOldEnough") === "yes") {
  //     setAppShow(true)
  //   } else {
  //     //ageCookie.set("isOldEnough", "no", { path: "/" })

  //     // ageCookie.set("isOldEnough", "no", {
  //     //   path: "/",
  //     //   maxAge: 2592000,
  //     //   secure: true,
  //     //   httpOnly: true,
  //     //   sameSite: "strict",
  //     // })
  //     setAppShow(false)
  //   }
  // }, [])

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO
        title="Skittles QueeR Codes"
        keywords={[`skittles`, `skittles queer codes`]}
      />

      {appShow ? <App /> : <Agegate setAppShow={setAppShow} />}
      {/* {appShow && <App setAppShow={setAppShow} />} */}
    </Layout>
  )
}

export default IndexPage
