import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import ayanPic from "../../images/influencer-cards/pics/ayan-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const AyanModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      ayanPic: file(
        relativePath: { eq: "influencer-cards/pics/ayan-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={ayanPic} alt="@THEMAKEUPMARIACHI" /> */}
                <Img
                  fluid={imageData.ayanPic.childImageSharp.fluid}
                  alt="@THEMAKEUPMARIACHI"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@THEMAKEUPMARIACHI</h4>
            <p>Ayan the Makeup Mariachi</p>
            <p>They/She | Elle/Ella</p>
            <p>
              Ayan Vasquez-Lopez (The Makeup Mariachi) is a non-binary Latinx
              mariachi musician, makeup artist, media producer, and drag
              performer based in Los Angeles, California. Through her work, Ayan
              strives to create authentic representation for queer brown gender
              non-conforming Latinx people in media and in real life. You can
              follow Ayan online to learn more about things like makeup,
              pronouns, and social activism!
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="https://bio.site/themakeupmariachi"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Support
              </a>
              <a
                href="https://www.instagram.com/ayanthepisces/"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>

        {/* <h4 className="text-uppercase mt-4 text-danger">Artist Statement</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut
          posuere felis, sed sodales ligula. Cras vitae efficitur urna.{" "}
        </p>

        <p>
          Phasellus accumsan justo viverra nisi pellentesque, vel mollis eros
          eleifend. Proin mollis aliquam felis quis dignissim. Fusce non iaculis
          ante, sed tincidunt arcu. Nam non viverra mauris. Nunc egestas
          tristique neque. Integer auctor porta accumsan.{" "}
        </p>

        <p>
          Curabitur id tortor ipsum. Fusce sit amet felis quis nibh tincidunt
          porttitor. Maecenas faucibus ipsum sit amet lectus feugiat vestibulum.
        </p> */}
      </Modal.Body>
    </Modal>
  )
}

export default AyanModal
