import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

//import arrrtaddict from "../../images/mural-cards/pics/arrrtaddict-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const ArrrtaddictModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      arrrtaddict: file(
        relativePath: { eq: "mural-cards/pics/arrrtaddict-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={arrrtaddict} alt="@ARRRTADDICT" /> */}
                <Img
                  fluid={imageData.arrrtaddict.childImageSharp.fluid}
                  alt="ARRRTADDICT"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@ARRRTADDICT</h4>
            <p></p>
            <p>
              She/Her/Boricua <span className="ml-4">Atlanta, Georgia</span>
            </p>
            <p>
              ARRRTADDICT is a Puerto Rican artist originally from Ft.
              Lauderdale. She focuses on creating art that portrays the vibrancy
              of Puerto Rican and African American culture with loose lines,
              bold typography and bright colors. Her art exudes positivity with
              embracing culture, spirituality, healing and evoking the change
              our community needs to unified and treated equally.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="http://www.arrrtaddict.com/"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Visit Shop
              </a>
              <a
                href="https://www.instagram.com/arrrtaddict/"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>

        <h4 className="text-uppercase mt-4 text-danger">Artist Statement</h4>
        <p>
          My coming out story is as loud as the colors in the rainbow. It was
          filled with so much love from the community, nightlife which many of
          us use as a place to feel free as well as supported and the ballroom
          scene where you find family. This mural is about representing the
          ballroom scene, the love and diversity within the LGBTQ+ community,
          and the sunshine that we are! No matter what we go through we keep
          shining, we keep loving and we keep living our lives louder. Live
          loud, keep loving and keep shining bright like the rainbow!
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default ArrrtaddictModal
