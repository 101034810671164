import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import shyannePic from "../../images/influencer-cards/pics/shyanne-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const ShyanneLindsayModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      shyannePic: file(
        relativePath: { eq: "influencer-cards/pics/shyanne-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={shyannePic} alt="@_shyannelindsay" /> */}
                <Img
                  fluid={imageData.shyannePic.childImageSharp.fluid}
                  alt="@_shyannelindsay"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@_SHYANNELINDSAY</h4>
            <p>Shyanne Lindsay</p>
            <p>She/Her/Hers</p>
            <p>
              Hailing from Rochester, NY, Shyanne Lindsay is a dancer, model and
              influencer. In the Ballroom scene, she is a part of the house of
              Escada where she walks fem Queen performance.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="https://venmo.com/shynmarc"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Support
              </a>
              <a
                href="https://www.instagram.com/_shyannelindsay/"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ShyanneLindsayModal
