import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import gregorLopesPic from "../../images/influencer-cards/pics/gregor-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const GregorLopesModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      gregorLopesPic: file(
        relativePath: { eq: "influencer-cards/pics/gregor-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={gregorLopesPic} alt="@PLAYWITHME39_" /> */}
                <Img
                  fluid={imageData.gregorLopesPic.childImageSharp.fluid}
                  alt="@PLAYWITHME39_"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@PLAYWITHME39_</h4>
            <p>Gregor Lopes</p>
            <p>He/Him</p>
            <p>
              Gregor is a Deaf, Queer, Latino who was born in Brazil and raised
              in Florida. He moved to NYC to discover himself and started
              performing, acting, dancing, streaming games, teaching ASL and
              managing an online business all while studying psychology. He
              enjoys bubble tea, cats, painting his nails and quality time with
              friends.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="https://linktr.ee/playwithme39_"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Support
              </a>
              <a
                href="https://www.instagram.com/playwithme39_/?hl=en"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default GregorLopesModal
