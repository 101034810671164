import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import tenderoniPic from "../../images/influencer-cards/pics/tenderoni-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const TenderoniModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      tenderoniPic: file(
        relativePath: { eq: "influencer-cards/pics/tenderoni-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={tenderoniPic} alt="@tenderoni88" /> */}
                <Img
                  fluid={imageData.tenderoniPic.childImageSharp.fluid}
                  alt="@tenderoni88"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@TENDERONI88</h4>
            <p>Tenderoni</p>
            <p>He/Him</p>
            <p>
              Tenderoni is a Chicago based Drag King and has been performing
              nationally for over four years. Inspired by the music and fashion
              of the 80’s and 90’s, Tenderoni is known for his colorful, high
              energy performances served with a side of goofiness. His most
              recent achievement is winning the title of Drag Queen of the Year
              2021.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="https://venmo.com/Tenderoni88"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Support
              </a>
              <a
                href="https://www.instagram.com/tenderoni88/"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TenderoniModal
