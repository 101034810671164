/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

// import { Container, Row, Col } from "react-bootstrap"

// import "../css/app.css"
// import "../css/responsive.css"

const Layout = ({ children, pageInfo }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../SkittlesPride/css/app.css" />
        <link rel="stylesheet" href="../SkittlesPride/css/responsive.css" />
        {/* <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/consent/75106614-e1c2-4a5b-acb3-5a8e888f2d73/OtAutoBlock.js"
        ></script> */}

        {/* Stage */}
        {/* <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="7d2d4249-afeb-48ff-88b7-a48039e771bb-test"
        ></script> */}

        {/* Production */}
        {/* <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="17ef9127-cca3-419d-81d1-0d6ae3423dd2-test"
        ></script> */}
      </Helmet>
      {children}
    </>
  )
}

export default Layout
