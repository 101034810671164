import React from "react"

const Footer = () => {
  return (
    <footer className="main-footer d-block">
      <div className="container">
        <ul>
          <li>
            <a
              href="https://www.mars.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Statement
            </a>
          </li>
          <li>
            <a
              href="https://www.mars.com/cookies-english"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Notice
            </a>
          </li>
          <li>
            <a
              href="https://www.mars.com/legal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Legal
            </a>
          </li>
          <li>
            <a
              href="https://www.mars.com/legal/ca-supply-chain-act"
              target="_blank"
              rel="noopener noreferrer"
            >
              CA Supply Chain Transparency Act
            </a>
          </li>
          <li>
            <a
              href="https://www.mars.com/modern-slavery-act"
              target="_blank"
              rel="noopener noreferrer"
            >
              Modern Slavery Act
            </a>
          </li>
          <li>
            <a
              href="https://www.mars.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </li>
          <li>
            <a
              href="https://www.mars.com/accessibility"
              target="_blank"
              rel="noopener noreferrer"
            >
              Accessibility
            </a>
          </li>
          <li>
            <a
              href="https://www.mars.com/mars-incorporated-adchoices-united-states"
              target="_blank"
              rel="noopener noreferrer"
            >
              AdChoices
            </a>
          </li>
          <li>
            <a
              href="http://www.mars.com/global/policies/note-to-parents/np-english"
              target="_blank"
              rel="noopener noreferrer"
            >
              Note to Parents
            </a>
          </li>
        </ul>
        <p className="copyright">
          &copy; 2021 Mars, Incorporated and its Affiliates. All Rights Reserved
        </p>
      </div>
    </footer>
  )
}

export default Footer
