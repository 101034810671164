import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"

// import jaeLin from "../../images/mural-cards/pics/doodlemealive-pic.png"
import iconInstagramColor from "../../images/icon-instagram-color.svg"

const JaeLinModal = (props) => {
  const imageData = useStaticQuery(graphql`
    query {
      jaeLin: file(
        relativePath: { eq: "mural-cards/pics/doodlemealive-pic.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="partners-modal"
    >
      <Modal.Body>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onHide}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <div className="mr-md-3">
            <div className="profile-pic-hldr position-relative">
              <figure className="mb-0 position-absolute">
                {/* <img src={jaeLin} alt="@DOODLEMEALIVE" /> */}
                <Img
                  fluid={imageData.jaeLin.childImageSharp.fluid}
                  alt="@DOODLEMEALIVE"
                />
              </figure>
            </div>
          </div>
          <div className="text-center text-md-left mt-3 mt-md-0">
            <h4 className="mb-0">@DOODLEMEALIVE</h4>
            <p>Jae Lin</p>
            <p>
              They/Them <span className="ml-4">Texas</span>
            </p>
            <p>
              Jae Lin is the hand lettering artist behind Doodle Me Alive, which
              features affirmations, cute illustrations, and other creative
              projects for trans liberation.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="https://www.patreon.com/doodlemealive"
                target="_blank"
                className="btn btn-primary rounded-pill"
                rel="noopener noreferrer"
              >
                Visit Shop
              </a>
              <a
                href="https://www.instagram.com/doodlemealive/?hl=en"
                target="_blank"
                className="mx-3"
                rel="noopener noreferrer"
              >
                <img src={iconInstagramColor} alt="Icon Instagram" />
              </a>
            </div>
          </div>
        </div>

        <h4 className="text-uppercase mt-4 text-danger">Artist Statement</h4>
        <p>
          To all my queer and trans family, friends, dear ones, I want to shout
          from the rooftops: "We have always existed. We have always belonged."
          How powerful it is to know that there have always been someones like
          us; there have always been bodies like ours; there is nothing more
          natural and timeless and abundant than all of these different facets
          of experience in love, gender, spirit, fluidity, and expansiveness.
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default JaeLinModal
